import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkAccountNameExistAction,
  generateVerificationCodeAction,
  getUerAccountsDetailsAction,
  lastLoginTrackAction,
  saveAccountNameAction,
  verifyVerificationCodeAction,
} from "../Actions/CommonActions";
import { useHistory, useLocation } from "react-router-dom";
import {
  Countries,
  LoadingDots,
  PhoneInput,
  Select,
  checkRolesAccess,
  isVodexLoggedin,
} from "../CommonComponents/Common";
import {
  swalErrorMessage,
  swalWarningMessage,
} from "../../../Constant/swalMessage";
import {
  checkValidationAccountAction,
  clearAccountReducerAction,
  logoutAction,
} from "../Store/Account/Account.action";
import { clearProjectReducer } from "../Projects/Store/projects.action";
import { clearCallSettingReducerAction } from "../ProjectSection/CallSettings/Store/callSetting.action";
import { clearAudienceReducer } from "../ProjectSection/AudienceSection/Store/Audience.action";

function AssignName() {
  const reducer = useSelector((reducer: any) => reducer);
  const { AccountReducer: {accountRoles, userDetails, selectedAccount} } = reducer

  let countries = [
    { value: "d", name: "f" },
    { value: "d", name: "f" },
    { value: "d", name: "f" },
  ];
  const countryCodes = Object.keys(countries);
  const countryNames = countryCodes.map((code) => countries[code]?.name);
  // console.log(countryNames);
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    name: "",
    phone: "",
    errPhone: false,
    errName: false,
    alreadyExist: false,
    alreadyExistValid: false,
    count: 0,
    country: "United States",
    verificationNumber: "",
    errCountry: false,
    spin: false,
    charErr: false,
  });
  const [resend, setResend] = useState(0);
  const [code, setCode] = useState("");
  const [show, setShow] = useState(false);
  const [showall, setShowall] = useState(false);
  const [timeLeft, setTimeLeft] = useState(59);
  const [showInput, setShowInput] = useState(false);
  const [hide, setHide] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false);

  const location = useLocation();
  const locSearch = localStorage.getItem("account-id");
  const {
    name,
    errName,
    alreadyExist,
    count,
    country,
    errCountry,
    spin,
    phone,
    errPhone,
    verificationNumber,
    charErr,
  } = state;

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      history.push("/");
    }
  }, []);

  const handleSave = async (e, nav) => {
    e.preventDefault();

    setShowInput(true);

    if (charErr) {
      swalWarningMessage("Special Characters are Not Allowed.");
      return;
    }

    if (country === "") {
      setState((prev) => ({ ...prev, errCountry: true }));
    } else if (name === "") {
      setState((prev) => ({ ...prev, errName: true }));
    } else {
      if (!alreadyExist) {
        setState((prev) => ({ ...prev, spin: true }));
        let accountId = locSearch;
        let currency = "usd";
        if (country === "India") {
          currency = "inr";
        }
        let obj = {
          accountName: name,
          accountId: accountId,
          currency,
        };
        await dispatch(saveAccountNameAction(obj, history));
        localStorage.removeItem("verifiedPhoneNumber");
        localStorage.removeItem("hide")
        if (nav) {
          history.push("/account/dashboard");
        }
        setState((prev) => ({ ...prev, spin: false }));
      }
    }
  };

  function checkSpecialChar(str) {
    const specialCharRegex = /[ !@#$%^&*(),.?":{}|<>;/'\[\]=+\-_`~\\]/g;
    return specialCharRegex.test(str);
  }

  useEffect(() => {
    const isSpecialChar = checkSpecialChar(name);

    if (count > 0) {
      if (name === " ") {
        setState((prev) => ({ ...prev, errName: true }));
      } else {
        setState((prev) => ({ ...prev, errName: false }));
      }
    }

    if (isSpecialChar) {
      setState((prev) => ({ ...prev, charErr: true }));
      return;
    } else {
      setState((prev) => ({ ...prev, charErr: false }));
    }

    const delayDebounceFn = setTimeout(async () => {
      if (name.trim()) {
        let res: any = await dispatch(checkAccountNameExistAction(name));
        if (!res?.data.available) {
          setState((prev) => ({
            ...prev,
            alreadyExist: true,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            alreadyExist: false,
          }));
        }
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [name]);

  const onChange = async (e) => {
    let val = e.target.value;
    e.persist();
    setState((prev) => ({ ...prev, name: val, count: 1 }));
  };
  const [showButton, setShowButton] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [reload, setReload] = useState(false);
  const [access, setAccess] = useState("")
  useEffect(() => {
    let timer;
    if (isRunning && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsRunning(false);
    }
    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, [isRunning, timeLeft]);

  useEffect(() => {
    const verifiedPhoneNumber = localStorage.getItem("verifiedPhoneNumber");
    if (verifiedPhoneNumber) {
      const parsedData = JSON.parse(verifiedPhoneNumber);
      setState((prevState) => ({
        ...prevState,
        name: parsedData.accName || "",
      }));
    }
  }, []);


  // Will fill the selectedAccount and userDetails
  useEffect(() => {
    async function init() {
    await  dispatch(getUerAccountsDetailsAction())
     setDataLoaded(true);
     }
     init()
   }, [])

    //  Will fill the accountRoles
  useEffect(() => {
    const init = async () => {
        const lastLoginObj = {
          accountUrl: selectedAccount.accountUrl,
          userId: userDetails._id,
          accountId: selectedAccount._id,
        };
        await dispatch(lastLoginTrackAction(lastLoginObj));
      }
    if (dataLoaded && selectedAccount && userDetails) {
      init();
    }
  }, [dataLoaded, selectedAccount, userDetails]);

  useEffect(() => {
    const accountName = selectedAccount?.accountName
    const role = checkRolesAccess(accountRoles)
    if (accountName) {
      if (role === "dashboard") {
        history.push("/account/dashboard")
      }else if (role === "project") {
        history.push("/account/projects")
      }
    }
  }, [accountRoles])

  useEffect(() => {
    const verifiedPhoneNumber = localStorage.getItem("verifiedPhoneNumber");

    if (verifiedPhoneNumber) {
      setReload(true);
    } else {
      setReload(false);
    }
  }, []);

  useEffect(() => {
    const hideLoc = localStorage.getItem("hide")
    
    if (hideLoc) {
      const hideBool = JSON.parse(hideLoc)

      if (hideBool === true) {
        setHide(true)
      }else {
        setHide(false)
      }
    }
  }, [])

  interface VerificationResponse {
    status: number;
    msg: string;
    errMsg?: string;
  }

  const generateVerificationCode = async (e) => {
    e.preventDefault();
    setTimeLeft(59);
    setIsRunning(true);
    setCode("");
    setResend((prev) => (prev + 1));

    if (charErr) {
      swalWarningMessage("Special Characters are Not Allowed.");
      return;
    }

    if (
      verificationNumber === "" ||
      country === "" ||
      verificationNumber.length <= 8
    ) {
      swalWarningMessage("A phone number and country are required.");
    } else {
      setState((prev) => ({ ...prev, spin: true }));
      let obj = {
        phoneNumber: `+${verificationNumber}`,
        country: country,
      };
      const res = (await dispatch(
        generateVerificationCodeAction(obj)
      )) as unknown as VerificationResponse;

      if (res?.status !== 200) {
        setShowButton(false);
        setResend(0)
        setState((prev) => ({ ...prev, spin: false }));
        return;
      }

      setState((prev) => ({ ...prev, spin: false }));
      setShowButton(true);
    }
  };

  const preventInvalidInput = (e) => {
    if(e.key === "e" || e.key === "+" || e.key === "-" || e.key === "E" || e.key === ".") {
      e.preventDefault()
    }
  }

  const verifyVerification = async () => {
    let localObj = {
      accName: name,
      verificationNumber,
      countryName: {
        name: country,
      },
    };
    localStorage.setItem("verifiedPhoneNumber", JSON.stringify(localObj));

    if (code.length < 4) {
      swalWarningMessage("Please enter valid code");
      localStorage.removeItem("verifiedPhoneNumber")
      return;
    }
    setState((prev) => ({ ...prev, spin: true }));
    let obj = {
      otp: code,
    };
    let res: any = await dispatch(verifyVerificationCodeAction(obj, history));
    if (res !== 200) {
      setShowall(false);
      setShow(false);
      setShowButton(true);
      setShowInput(false);
      setReload(false);
      setState((prev) => ({ ...prev, spin: false }))
      localStorage.removeItem("verifiedPhoneNumber")
      return;
    }
    if (res === 200) {
      setShowall(true);
      setShow(true);
      setShowButton(false);
      setShowInput(true);
      setReload(true);
    }
    setState((prev) => ({ ...prev, spin: false }));
    setHide(true)
    localStorage.setItem("hide", JSON.stringify(true))
  };
  let selobj = { name: "United States" };

  return (
    <div className="limiter">
      <div
        className={`container-login100 container-logout  ${
          !isVodexLoggedin() && "whitelabel-background"
        }`}
      >
        {/* Logout Button */}
        <span
          title="Logout"
          className="new-logout"
          style={
            isVodexLoggedin()
              ? { backgroundColor: "#fd7e14", color: "whitesmoke" }
              : { backgroundColor: "white", color: "#737373" }
          }
          onClick={async () => {
            await dispatch(clearAccountReducerAction());
            await dispatch(clearProjectReducer());
            await dispatch(clearAccountReducerAction());
            await dispatch(clearCallSettingReducerAction());
            await dispatch(clearAudienceReducer());
            await dispatch(logoutAction(history));
            history.push("/");
          }}
        >
          <i className="fas fa-sign-out-alt fa-sm fa-fw"></i>
        </span>
        {/* ------------- */}

        <div className="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
          <div className="wrap-input100 validate-input m-b-2">
            <span className="label-input100"></span>

            <input
              className="input100 "
              type="text"
              name="email"
              placeholder="Enter Account Name"
              required={true}
              value={name}
              onChange={onChange}
              maxLength={25}
            />
            {errName && (
              <span
                className="alert-validate"
                data-validate={"Account name is required"}
              ></span>
            )}

            {charErr && (
              <span
                className="alert-validate"
                data-validate={"Special Characters not allowed"}
              ></span>
            )}

            {alreadyExist && (
              <span
                className="alert-validate"
                data-validate={"This account name is not available"}
              ></span>
            )}
            <span className="focus-input100" data-symbol="&#xf206;"></span>
          </div>
          {isVodexLoggedin() ? (
            <>
              <div
                className="wrap-input100 validate-input m-b-2"
                style={{ zIndex: 100 }}
              >
                <span className="label-input100"></span>

                <Select
                  maxMenuHeight={160}
                  className="basic-single z-index-high"
                  classNamePrefix="select"
                  value={
                    Countries?.find((name: any) => name.value === country) ||
                    JSON.parse(
                      localStorage.getItem("verifiedPhoneNumber") || "{}"
                    )?.countryName
                  }
                  isDisabled={false}
                  isSearchable={true}
                  options={Countries}
                  getOptionLabel={(e: any) => e.name}
                  getOptionValue={(e: any) => e.name}
                  onChange={(e) => {
                    setState((prev) => ({
                      ...prev,
                      country: e?.name || "",
                    }));
                  }}
                  placeholder="Select Country"
                  defaultValue={selobj}
                />

                <span className="focus-input100" data-symbol="&#xf206;"></span>
              </div>
              <div
                style={{ zIndex: 99 }}
                className="wrap-input100 validate-input m-b-23"
              >
                <span className="label-input100"></span>

                <PhoneInput
                  disabled={showInput || reload || showButton}
                  specialLabel={""}
                  country={"us"}
                  onChange={(e) => {
                    setState((prev) => ({
                      ...prev,
                      verificationNumber: e,
                    }));
                  }}
                  value={
                    verificationNumber ||
                    JSON.parse(
                      localStorage.getItem("verifiedPhoneNumber") || "{}"
                    )?.verificationNumber
                  }
                  enableLongNumbers={true}
                />

                <span className="focus-input100"></span>
              </div>

              {showButton && (
                <div className="otp-input-container">
                  <div className={resend <= 2 ? "form-card" : "form-card-otp"}>
                    <p className="form-card-prompt">
                      Enter 4-digit Verification code received on SMS
                    </p>
                    <div className="form-card-input-wrapper">
                      <input
                        value={code}
                        onKeyDown={preventInvalidInput}
                        className="form-card-input"
                        placeholder="____"
                        maxLength={4}
                        type="number"
                        onChange={(e) => {
                          e.persist();
                          console.log();
                          if (e.target.value.length <= 4) {
                            setCode(e.target.value);
                          }
                        }}
                      />
                      <div className="form-card-input-bg"></div>
                    </div>

                    <div style={{display:'flex', flexDirection: "column",justifyContent:"center", alignItems:"center"}}>
                      <p className="call-again mb-0">
                        <span 
                        onClick={() => {
                          setShowButton(false) 
                          setResend(0)
                          setShowInput(false)
                        }}
                        className="underlined">Wrong Number ?</span>
                      </p>



                      {resend <= 2 ? (
                      <p className="call-again">
                        <span
                          className="underlined"
                          onClick={
                            timeLeft === 0 ? generateVerificationCode : () => {}
                          }
                          style={{
                            cursor: timeLeft === 0 ? "pointer" : "not-allowed",
                          }}
                        >
                          Resend Code
                        </span>{" "}
                        {timeLeft !== 0 && `in 00:${timeLeft} seconds`}
                      </p>
                    ) : (
                      <p>
                        Haven’t received the code? ?
                        <span
                          style={{ cursor: "pointer" }}
                          className="underlined"
                          onClick={(e) => {
                            handleSave(e, true);
                          }}
                        >
                          {" "}
                          Click here to continue
                        </span>
                      </p>
                    )}
                    </div>
                  </div>
                </div>
              )}

              {(show || reload) && (
                <div className="container-login100-form-btn">
                  <div className="wrap-login100-form-btn">
                    <div className="login100-form-bgbtn"></div>
                    <button
                      className="login100-form-btn"
                      type="submit"
                      onClick={(e) => handleSave(e, false)}
                      disabled={spin}
                    >
                      {spin ? <LoadingDots /> : "Save"}
                    </button>
                  </div>
                </div>
              )}

              {!showButton && !showall && !reload && (
                <div className="container-login100-form-btn">
                  <div className="wrap-login100-form-btn">
                    <div className="login100-form-bgbtn"></div>
                    <button
                      className="login100-form-btn"
                      type="submit"
                      onClick={generateVerificationCode}
                      disabled={spin}
                    >
                      {spin ? <LoadingDots /> : "Send Verification Code"}
                    </button>
                  </div>
                </div>
              )}

              {showButton && !showall && (
                <div 
                style={{margin:"10px 0"}}
                className="container-login100-form-btn">
                  <div
                    className="wrap-login100-form-btn"
                    style={{ marginTop: "-10px" }}
                  >
                    <div className="login100-form-bgbtn"></div>
                    <button
                      className="login100-form-btn"
                      type="submit"
                      onClick={verifyVerification}
                      disabled={spin}
                    >
                      {spin ? <LoadingDots /> : "Verify"}
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="container-login100-form-btn mt-4">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button
                  className="login100-form-btn"
                  type="submit"
                  onClick={(e) => handleSave(e, false)}
                  disabled={spin}
                >
                  {spin ? <LoadingDots /> : "Save"}
                </button>
              </div>
            </div>
          )}
          {hide === false ? (isVodexLoggedin() &&(
            <>
              {" "}
              <br />
              <span
                className="call-again mt-2 "
                style={{
                  cursor: "auto",
                }}
              >
                Didn't received code? Please contact support at {""}
                <a href="mailto:support@vodex.ai">support@vodex.ai</a>
              </span>
            </>
            )
          ) : null }
        </div>
      </div>
    </div>
  );
}

export default AssignName;
