import React, { Fragment, Suspense, lazy, useEffect, useState } from "react";
import { Switch, Route } from "react-router";
import LeftMenuProject from "./LeftMenuProject";
import { getUerAccountsDetailsAction } from "../Actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import TopMenuAccount from "./TopMenuAccount";
import { axiosClient } from "../../../Constant/apiConstant";
import {
  getConstantData,
  getDashboardChartAction,
  getDashboardDataAction,
} from "../Store/Account/Account.action";
import { getProjectsAction } from "../Projects/Store/projects.action";
import { isEmpty } from "lodash";
import SkeletonLodingWithCards from "../../../Constant/SkeletonLoding";
import {
  LoadingDotsSpin,
  checkRolesAccess,
  goToMentionedRoutes,
  isVodexLoggedin,
} from "../CommonComponents/Common";
import { useHistory, useLocation } from "react-router-dom";
import IntegrationNotistack from "../CommonComponents/SnackBar";
import packageJson from "./../../../../package.json";
import Support from "./Support";
import CallHistory from "../ProjectSection/CampaignSection/CallHistory/CallHistory";
import Home from "../Dashboard/Dashboard";
import Whatsapp from "../Integration/Whatsapp/Whatsapp";
import TotalCalls from "../Admin/TotalCalls";
import GatewayStatus from "../Admin/GatewayStatus";
const UserProfile = lazy(() => import("../Profile/Profile"));
const ComingSoon = lazy(() => import("../CommonComponents/ComingSoon"));
const USDPayment = lazy(() => import("../Payments/USDPayment"));
const Projects = lazy(() => import("../Projects/Projects"));
const Admin = lazy(() => import("../Admin"));
const AccessManager = lazy(() => import("../AccessManger/AccessManager"));
const Invite = lazy(() => import("../AccessManger/Invite"));
const VideoComponent = lazy(() => import("../CommonComponents/videomodal"));
const AccountHistory = lazy(
  () => import("../AccessManger/AccountHistory/AccountHistory")
);
const RechargeBody = lazy(() => import("../Admin/RechargeBody"));
const VoiceCloning = lazy(
  () => import("../AccessManger/VoiceCloning/VoiceCloning")
);
const CustomSupportIframe = lazy(() => import("./CustomSupportIframe"));
const UsageData = lazy(() => import("../Admin/UsageData"));
const TopupForm = lazy(() => import("../Admin/TopupForm"));
const GHLVerfication = lazy(() => import("../AccountSection/GHLVerification"));
const TwillioIntegration = lazy(
  () => import("../Integration/TwillioIntegration")
);
const GhlIntegration = lazy(() => import("../Integration/GhlIntegration"));
const CustomSupportIframeLink = lazy(() => import("./CustomSupportIframeLink"));
const Index = lazy(() => import("../ProjectSection/Index"));
const Integration = lazy(() => import("../Integration/Integration"));
const QueueStatus = lazy(() => import("../Admin/QueueStatus"));
const RunningCampaigns = lazy(
  () =>
    import("../ProjectSection/CampaignSection/CampaignRuns/RunningCampaigns")
);
const IncreaseCps = lazy(
  () =>
    import("../AccountSection/IncreaseCps")
);
const IncreaseCapacity = lazy(
  () =>
    import("../AccountSection/IncreaseCapacity")
);





const Routes: React.FC = () => {
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  let Stalker = locSearch.get("stalker");
  const currentVersion = packageJson.version;
  const history = useHistory();
  const dispatch = useDispatch();
  const reducer = useSelector((reducer: any) => reducer);
  const [spin, setSpin] = useState(true);
  const [videoId, setVideoId] = useState("");
  const [versionShow, setVersionShow] = useState(false);
  const {
    AccountReducer: {
      userDetails: { email, accounts, _id, role },
      selectedAccount,
      constantData,
      accountRoles,
    },
  } = reducer;

  useEffect(() => {
    if (
      !isEmpty(constantData) &&
      currentVersion !== constantData?.latestVersion
    ) {
      setVersionShow(true);
    }
  }, [constantData]);

  useEffect(() => {
    const init = async () => {
      setSpin(true);
      await dispatch(getUerAccountsDetailsAction());
      // swalWarningMessage(
      //   "Oh, what a surprise! Another day of high traffic. How utterly unexpected. 😏, Give us a few seconds.",
      //   8000
      // );
    };
    init();
  }, []);

  useEffect(() => {
    let terry = 0;
    const script = document.createElement("script");
    if (window.location.origin === "https://aivoicelogin.com") {
      terry = 1;
      script.onload = () => {
        (window as any).voiceflow.chat.load({
          verify: { projectID: "65840d87c9073351e401aa85" },
          url: "https://general-runtime.voiceflow.com",
          versionID: "production",
        });
      };
      script.src = "https://cdn.voiceflow.com/widget/bundle.mjs";
      script.type = "text/javascript";
      document.getElementsByTagName("head")[0].appendChild(script);
    }
    return () => {
      if (terry > 0) {
        document.getElementsByTagName("head")[0].removeChild(script);
      }
    };
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    let kl = 0;
    let cem = 0;

    if (window.location.origin === "https://voice.acquisitionai.com") {
      script.async = true;
      script.src = "https://public.produktly.com/js/main.js";
      script.setAttribute("id", "produktlyScript");
      script.dataset.clientToken =
        "a428727aeeaa48faf71d98f2ebbaacbc5bc66ca7f9b14413e33d071dee4d919f0541d9699be0d760220639b279bc7735039de009cba805d2d7cc51dfe3229d6fa0f9a209d7a30119a71f61b4c61837ee9e69be23e46bbe98ed7b2b525623881bf2606c37";
      document.head.appendChild(script);
      cem = 1;
    }

    return () => {
      if (kl > 0) {
        document.body.removeChild(script);
      }
      if (cem > 0) {
        document.head.removeChild(script);
      }
    };
  }, []);

  const { pathname } = useLocation();
  const projectId = pathname?.split("/")[3];

  useEffect(() => {
    if (!projectId) {
      axiosClient.defaults.headers.common["projectId"] = null;
    }
  }, [projectId])
  useEffect(() => {
    if (!isEmpty(selectedAccount)) {
      axiosClient.defaults.headers.common[
        "dbUrl"
      ] = `${selectedAccount?.accountUrl}`;
      axiosClient.defaults.headers.common["projectId"] = projectId;
      const init = async () => {
        await goToMentionedRoutes(
          selectedAccount,
          _id,
          history,
          dispatch,
          true
        );
        await dispatch(getConstantData());
        setSpin(false);
        await dispatch(getDashboardDataAction());
        await dispatch(getDashboardChartAction());
        await dispatch(getProjectsAction());
      };
      init();
    }
  }, [selectedAccount]);

  let pathName = window.location.pathname;

  useEffect(() => {
    let find = constantData?.videosLink?.find((x) => {
      if (x.path.includes("projectId")) {
        let newPath = x.path.replace("projectId", projectId);
        return newPath === pathName ? true : false;
      } else {
        return x.path === pathName;
      }
    });
    if (find) {
      setVideoId(find.videoId);
    } else {
      setVideoId("");
    }
  }, [pathName, constantData]);
  const [access, setAccess] = useState("");

  useEffect(() => {
    let acc: any = checkRolesAccess(accountRoles);
    setAccess(acc);
  }, [accountRoles]);

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (!token) {
      history.push("/")
    }
  }, [])

  return (
    // <Elements stripe={stripePromise}>
    <Fragment>
      {selectedAccount?.isPaused && (
        <div className="transparent-alert word-wrap">
          {selectedAccount?.pauseMsg}
        </div>
      )}
      {versionShow && (
        <div className="transparent-alert-black">
          <p>
            For the latest enhancements, execute a hard refresh as your current
            version is not the latest one available.
          </p>
        </div>
      )}

      {/* {isVodexLoggedin() && videoId && <VideoComponent videoId={videoId} />} */}
      <IntegrationNotistack />
      <LeftMenuProject />

      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <TopMenuAccount />
          <div className="container-fluid">
            {spin ? (
              <SkeletonLodingWithCards count={4} />
            ) : (
              <Suspense fallback={<LoadingDotsSpin />}>
                {constantData?.logAccess?.includes(Stalker) && (
                  <Route path="/account/queue">
                    <QueueStatus />
                  </Route>
                )}
                {constantData?.logAccess?.includes(Stalker) && (
                  <Route path="/account/all-calls">
                    <TotalCalls />
                  </Route>
                )}
                {constantData?.logAccess?.includes(Stalker) && (
                  <Route path="/account/gateways">
                    <GatewayStatus />
                  </Route>
                )}
                <Switch>
                  <Route path="/account/whatsapp-integration">
                    <Whatsapp />
                  </Route>
                  <Route path="/account/project/:projectId/">
                    <Index />
                  </Route>
                  <Route path="/account/call-history">
                    <CallHistory />
                  </Route>
                  <Route path="/account/support-email">
                    <Support />
                  </Route>
                  <Route path="/account/payments">
                    {selectedAccount?.currency === "inr" ? (
                      <USDPayment />
                    ) : isVodexLoggedin() ? (
                      <USDPayment />
                    ) : (
                      <USDPayment />
                    )}
                  </Route>
                  {access === "dashboard" && (
                    <Route path="/account/voice-cloning">
                      {isVodexLoggedin() ? <VoiceCloning /> : <VoiceCloning />}
                    </Route>
                  )}
                  {access === "dashboard" && (
                    <Route path="/account/invite">
                      <Invite />
                    </Route>
                  )}
                  {access === "dashboard" && (
                    <Route path="/account/access">
                      <AccessManager />
                    </Route>
                  )}
                  {access === "dashboard" && (
                    <Route path="/account/history">
                      <AccountHistory />
                    </Route>
                  )}
                  <Route path="/account/profile">
                    <UserProfile />
                  </Route>
                  <Route path="/account/projects">
                    <Projects />
                  </Route>
                  <Route path="/account/topup">
                    <RechargeBody />
                  </Route>
                  <Route path="/account/integrations">
                    <Integration />
                  </Route>
                  <Route path="/account/running-campaign">
                    <RunningCampaigns />
                  </Route>
                  <Route path="/account/capacity-settings">
                    <IncreaseCapacity />
                  </Route>
                  <Route path="/account/cps-settings">
                    <IncreaseCps />
                  </Route>
                  <Route path="/account/twillio-integration">
                    <TwillioIntegration />
                  </Route>
                  {constantData?.ghlShow?.includes(email) && (
                    <Route path="/account/ghl-integration">
                      <GhlIntegration />
                    </Route>
                  )}
                  <Route path="/account/crm-verification">
                    <GHLVerfication />
                  </Route>
                  {(role === "sales" || role === "super_admin") && (
                    <Route path="/account/account-topup">
                      <TopupForm />
                    </Route>
                  )}

                  {role === "super_admin" && (
                    <Route path="/account/usage">
                      <UsageData />
                    </Route>
                  )}
                  {access === "dashboard" && (
                    <Route path="/account/dashboard">
                      <Home />
                    </Route>
                  )}
                  {window.location.origin ===
                    "https://app.gencallsystem.com" && (
                      <Route path="/account/help-desk">
                        <CustomSupportIframeLink
                          link={" https://gencallsystem.eu"}
                        />{" "}
                      </Route>
                    )}

                  {window.location.origin === "https://app.voizer.ai" && (
                    <Route path="/account/help-desk">
                      <CustomSupportIframe />
                    </Route>
                  )}
                  {window.location.origin === "https://aivoicelogin.com" && (
                    <Route path="/account/training">
                      <CustomSupportIframeLink
                        link={"https://www.closerbot.ai/training-page"}
                      />
                    </Route>
                  )}
                  {(window.location.origin === "https://app.voizer.ai" ||
                    (window.location.origin === "https://ghl.agencys.ai" &&
                      role === "admin")) && (
                      <Route path="/account/help-desk">
                        <CustomSupportIframe />
                      </Route>
                    )}

                  {(role === "super_admin" || role === "admin") && (
                    <>
                      <Route path="/account/admin">
                        <Admin />
                      </Route>
                      <Route path="/account/recharge">
                        <RechargeBody />
                      </Route>
                    </>
                  )}
                </Switch>
              </Suspense>
            )}
          </div>
        </div>
      </div>
    </Fragment>
    // </Elements>
  );
};

export default Routes;
