import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactAudioPlayer from "react-audio-player";
import "../../../../../styles/callStatus.css";
import { Col, OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  getCallHistoryAction,
  getCallHistoryCountAction,
  exportCallHistoryAction,
} from "../Store/Campaign.action";
import {
  CommanOverLayonHover,
  LoadingDots,
  LoadingDotsSpin,
  PAGE_SIZE_50,
  converTime,
  getIndexValue,
  isEmpty,
  isPriceWaived,
  renderThumb,
} from "../../../CommonComponents/Common";
import CommonDrawer from "../../../CommonComponents/CommonDrawer";
import SkeletonLodingWithCards from "../../../../../Constant/SkeletonLoding";
import InfiniteScroll from "react-infinite-scroll-component";
import { shortenString } from "../../../../../Constant/helper";
import FilterDrawerBody from "../CampaignDetails/FilterDrawerBody";
import SummaryModel from "../CampaignDetails/SummaryModel";
import SearchBar from "../../../CommonComponents/SearchBar";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { updateCurrentPath } from "../../../../../store/actions/root.actions";
import CallLogs from "./CallLogs";
import { addNotificationAction } from "../../../Store/Account/Account.action";
import { Scrollbars } from "react-custom-scrollbars";
import { Pagination } from "@mui/material";
import TopCard from "../../../../../common/components/TopCard";
import { swalWarningMessage } from "../../../../../Constant/swalMessage";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Select from "react-select";

const customStyle = {
  control: (base) => ({
    ...base,
    height: "35px",
    minHeight: "35px",
    border: "none",
    boxShadow: "none",
    borderRadius: "13px",
    maxWidth:"200px"
  }),
  valueContainer: (base) => ({
    ...base,
    height: "35px",
    display: "flex",
    alignItems: "center",
  }),
  input: (base) => ({
    ...base,
    margin: "0px",
  }),
  menu:(base) => ({
    ...base,
    borderRadius:"10px"
  })
};

function CallHistory(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  let filterData: any = localStorage.getItem("callHistoryFilterData");
  let filterParsed = JSON.parse(filterData);

  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  let campaignRuns = locSearch.get("campaignRuns");
  let campaignId = locSearch.get("campaign-id");
  let Stalker = locSearch.get("stalker");
  let count = 0;
  const [loading, setLoading] = useState(false);
  const reducer: any = useSelector((state) => state);
  const {
    AccountReducer: {
      constantData,
      userDetails: { email },
    },
    CampaignReducer: { callHistoryDetails, callHistoryDetailsTotalCounts },
  } = reducer;

  const [state, setState] = useState<any>({
    CALL_STATUS: constantData?.callStatus,
    campaignRunListData: [],
    showCreateButton: false,
    exportSpin: false,
    newCampaignData: [],
    summaryModal: false,
    recordingUrl: "",
    selectedCampaignRun: "",
    filterDrawer: false,
    countObj: {},
    insightsPopup: false,
    insightsPopupView: false,
    trackEnabledRecording: [],
    pageIndex: 1,

    durationFilter: "",
    statusFilter: [],
    outComefilter: [],
    searchText: filterParsed?.searchText || "",
    min: "",
    max: "",
    selectedProjectId: "",
  });
  const [filterdata, setFilterData] = useState<any>([]);
  const [filterName, setFilterName] = useState("");
  const {
    campaignRunListData,
    summaryModal,
    filterDrawer,
    countObj,
    trackEnabledRecording,
    pageIndex,
    durationFilter,
    statusFilter,
    outComefilter,
    CALL_STATUS,
    searchText,
    exportSpin,
    selectedProjectId,
  } = state;

  const {
    AccountReducer: { selectedAccount },
    RecordingReducer: { recordings },
    ProjectReducer: { projectList },
  } = reducer;
  const currentDate = moment().format("YYYY-MM-DD");

  const [dateRange, setDateRange] = useState([
    new Date(filterParsed?.startDate || currentDate),
    new Date(filterParsed?.endDate || currentDate),
  ]);
  const [startDate, endDate] = dateRange;
  const [call_uuid, setCall_uuid] = useState("");
  const [show, setShow] = useState(false);
  let callFn = async () => {
    localStorage.setItem(
      "callHistoryFilterData",
      JSON.stringify({
        startDate,
        endDate,
        searchText,
      })
    );
    if (startDate === null || endDate === null) {
      dispatch(
        addNotificationAction("Please select the valid date", "warning")
      );
      return;
    }
    if (count === 0) {
      setLoading(true);
      count++;
    }
    let obj = {
      pageIndex: 1,
      pageSize: PAGE_SIZE_50,
      duration:
        durationFilter === "Custom"
          ? `${state?.min}-${state?.max}`
          : durationFilter?.split(" ")[0],
      callStatus: statusFilter,
      outcome: outComefilter,
      searchText: searchText,
      from: moment(startDate).format("YYYY-MM-DD"),
      to: moment(endDate).format("YYYY-MM-DD"),
      projectId: selectedProjectId,
    };
    await dispatch(getCallHistoryAction(obj));
    setLoading(false);
    setState((prev) => ({
      ...prev,
      pageIndex: 1,
    }));
    await dispatch(getCallHistoryCountAction(obj));
  };
  useEffect(() => {
    dispatch(updateCurrentPath("Call History", ""));
  }, []);

  useEffect(() => {
    callFn();
  }, [dispatch]);

  useEffect(() => {
    if (callHistoryDetails) {
      setState((prev) => ({
        ...prev,
        campaignRunListData: callHistoryDetails,
      }));
    }
  }, [callHistoryDetails]);

  useEffect(() => {
    if (recordings?.length > 0) {
      let arr: any = [];
      for (const record of recordings) {
        if (record?.track === true) {
          arr.push({
            name: record.recordingName,
            tag: record.tag,
          });
        }
      }
      setState((prev) => ({
        ...prev,
        trackEnabledRecording: arr,
      }));
    }
  }, [recordings]);

  const toggle = (name = "summaryModal") => {
    setState((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const getOutcome = (data) => {
    let trackResult: any = [];
    if (data?.outComes) {
      data.outComes.forEach((da) => {
        let kl = trackEnabledRecording.find((kl) => kl.tag === da);
        if (kl) {
          trackResult.push(kl.name);
        }
      });

      trackEnabledRecording?.forEach((track) => {
        if (data.outComes.includes(track.tag)) {
          trackResult.push(track.name);
        }
      });
      return trackResult.length > 0 ? trackResult[trackResult?.length - 1] : "";
    } else {
      return "";
    }
  };

  const getName = (phone) => {
    const firstName = phone?.customParameters?.firstName || "";
    const lastName = phone?.customParameters?.lastName || "";
    return `${firstName} ${lastName}`.trim() || "No Name";
  };

  const openModal = (selectedCampaignRun) => {
    setState((prev) => ({
      ...prev,
      summaryModal: true,
      recordingUrl: selectedCampaignRun.recordingUrl,
      selectedCampaignRun: selectedCampaignRun,
    }));
  };
  const findProjectName = (id) => {
    let name = projectList?.find((pro) => pro?._id === id);
    return name?.projectName || "";
  };
  const popover = (data) => {
    return (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Call Information</Popover.Title>
        <Popover.Content>
          Call Reference Id : <strong>{data}</strong>
        </Popover.Content>
      </Popover>
    );
  };

  const redirectValidation = (callData) => {
    if (callData?.isDeleted) {
      swalWarningMessage("Project is Deleted.");
      return;
    } else if (callData?.campaignDeleted) {
      swalWarningMessage("Campaign is deleted.");
      return;
    } else {
      let obj = {
        _id: callData.campaignId,
        projectId: callData?.projectId,
        range: callData?.range,
        campaignName: callData?.campaignName,
        campiagnRunTime: callData?.createdAt,
      };
      localStorage.setItem("campaign", JSON.stringify(obj));
      history.push(
        `/account/project/${callData?.projectId}/campaign-runs?campaignid=${callData?.campaignId}&campdate=${callData?.createdAt}`
      );
    }
  };
  const ExportCSV = async () => {
    setState((prev) => ({
      ...prev,
      exportSpin: true,
    }));
    let send;
    if (filterdata.length > 0) {
      send = filterdata;
    }
    if (filterName !== "") {
      send = filterName;
    }

    let obj1 = {
      pageIndex: 1,
      pageSize: 100,
      duration:
        durationFilter === "Custom"
          ? `${state?.min}-${state?.max}`
          : durationFilter?.split(" ")[0],
      callStatus: statusFilter,
      outcome: outComefilter,
      searchText: searchText,
      from: moment(startDate).format("YYYY-MM-DD"),
      to: moment(endDate).format("YYYY-MM-DD"),
      all: true,
      projectId: selectedProjectId,
    };
    let res: any = await dispatch(exportCallHistoryAction(obj1));
    let matchArr = [
      "mandatory_params",
      "firstName",
      "lastName",
      "FirstName",
      "LastName",
      "phone",
      "PhoneNum",
    ];
    if (res) {
      let arr: any = [];
      res?.forEach((kl) => {
        let callHistory = kl?.combinedData?.campaignRunDetails;
        let copy = callHistory?.customParameters;
        let obj: any = {};
        obj["First Name"] = copy?.FirstName || "--";
        obj["Last Name"] = copy?.LastName || "--";
        obj["Phone number"] = callHistory?.phone || "--";
        obj["Call UUID"] = callHistory?.call_uuid || "";
        obj["Calling Status"] = CALL_STATUS[callHistory?.callStatus] || "";
        obj["Cost"] = callHistory?.newCost || "";
        if (selectedAccount?.params?.pricesWaivers) {
          obj["Cost"] = "--";
        }
        obj["Recording Url"] = callHistory?.callRecordingUrl || "";
        if (!isEmpty(copy)) {
          for (const customKeys in copy) {
            if (!matchArr.includes(customKeys)) {
              obj[customKeys] = copy[customKeys];
            }
          }
        }
        arr.push(obj);
      });
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(arr);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, `Call History Report` + fileExtension);
    }
    setState((prev) => ({
      ...prev,
      exportSpin: false,
    }));
  };

  const productElementsAlreadyRun: (JSX.Element | null)[] =
    campaignRunListData?.map((data: any, idx) => {
      let campaign = data?.combinedData?.campaignRunDetails;
      let callData = data?.combinedData;
      const { callStatus } = campaign;
      return (
        <tr key={idx}>
          <th scope="row">
            {pageIndex > 1
              ? getIndexValue(pageIndex, PAGE_SIZE_50, idx)
              : idx + 1}
          </th>
          <td>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="tooltip-disabled"> {getName(campaign)}</Tooltip>
              }
              rootClose={true}
              rootCloseEvent={"click"}
              onExit={() => {
                // console.log("cliii");
              }}
            >
              <span className="d-inline-block">
                <span style={{ pointerEvents: "none" }}>{campaign.phone} </span>
              </span>
            </OverlayTrigger>
          </td>
          <td style={{ width: "120px" }}>
            <button
              style={{
                backgroundColor:
                  !isEmpty(constantData) &&
                  constantData?.callStatusColour[callStatus]
                    ? constantData?.callStatusColour[callStatus]
                    : "red",
                cursor: "default",
                minWidth: "140px",
                color: callStatus === "701" ? "black" : "white",
              }}
              className="btn btn-light mr-1 no-padding"
            >
              {!isEmpty(CALL_STATUS) &&
                shortenString(CALL_STATUS[callStatus], 10)}
            </button>
          </td>
          <td>
            <div className="d-flex">
              {trackEnabledRecording?.length > 0 && getOutcome(campaign)}
              {campaign?.call_uuid && (
                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  overlay={popover(campaign?.call_uuid)}
                  rootClose={true}
                  rootCloseEvent={"click"}
                  onExit={() => {}}
                >
                  <span className="d-inline-block">
                    <span
                      className={`fas fa-info ml-3 `}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                    </span>
                  </span>
                </OverlayTrigger>
              )}
            </div>
          </td>
          <td>
            {selectedAccount?.params?.pricesWaivers
              ? "--"
              : campaign.newCost || "--"}
          </td>
          <td>
            <button
              disabled={callStatus !== "703"}
              className="btn btn-info no-padding"
              onClick={() => openModal(campaign)}
            >
              Summary
            </button>
          </td>

          <td className="w-20">
            <ReactAudioPlayer
              src={
                campaign?.callRecordingUrl ||
                `https://storage.googleapis.com/botstream-recordings/${campaign?.call_uuid}.mp3`
              }
              controls
              style={{
                minWidth: "40px",
                height: "30px",
              }}
            />
          </td>
          <td>
            <span className="d-flex justify-content-center">
              {" "}
              <CommanOverLayonHover
                className="custom-button-icon"
                content={
                  <ul
                    style={{
                      listStyleType: "none",
                      padding: 0,
                    }}
                  >
                    <li>
                      <strong>
                        {" "}
                        Project Name :{" "}
                        {shortenString(callData?.projectName, 20)}
                      </strong>
                    </li>
                    <li>
                      <strong>
                        {" "}
                        Campaign Name : {shortenString(callData?.campaignName)}
                      </strong>
                    </li>
                    <li>
                      <strong>
                        {" "}
                        Campaign Run Time : {converTime(callData?.createdAt)}
                      </strong>
                    </li>
                  </ul>
                }
                icon={"info"}
                title={"Call Details"}
                type={"hover"}
              />{" "}
              <button className="custom-button-icon" title="redirect">
                <i
                  className="fas fa-external-link-alt cursor-pointer"
                  onClick={() => redirectValidation(callData)}
                ></i>
              </button>
              {constantData?.logAccess?.includes(Stalker) && (
                <button
                  className="custom-button-icon"
                  title="Logs"
                  onClick={() => {
                    setCall_uuid(campaign.call_uuid);
                    setShow(true);
                  }}
                >
                  Logs
                </button>
              )}
            </span>
          </td>
        </tr>
      );
    });

  const obj = {
    selectedProjectId,
    campaignRuns,
    setMainState: setState,
    trackEnabledRecording,
    mainState: state,
    campaignId,
    startDate,
    endDate,
  };

  const handlePageNextInScroll = async () => {
    let index = pageIndex;
    index += 1;
    let obj = {
      pageIndex: index,
      pageSize: PAGE_SIZE_50,
      duration:
        durationFilter === "Custom"
          ? `${state?.min}-${state?.max}`
          : durationFilter?.split(" ")[0],
      callStatus: statusFilter,
      outcome: outComefilter,
      isScrolled: true,
      searchText: searchText,
      from: startDate,
      to: endDate,
      projectId: selectedProjectId,
    };
    await dispatch(getCallHistoryAction(obj));
    setState((prev) => ({
      ...prev,
      pageIndex: index,
    }));
  };
  const handlePageNextInScroll1 = async (ind) => {
    let obj = {
      pageIndex: ind,
      pageSize: PAGE_SIZE_50,
      duration:
        durationFilter === "Custom"
          ? `${state?.min}-${state?.max}`
          : durationFilter?.split(" ")[0],
      callStatus: statusFilter,
      outcome: outComefilter,
      // isScrolled: true,
      searchText: searchText,
      from: startDate,
      to: endDate,
      projectId: selectedProjectId,
    };
    await dispatch(getCallHistoryAction(obj));
    setState((prev) => ({
      ...prev,
      pageIndex: ind,
    }));
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      callFn();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);
  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };
  return (
    <div>
      <div className="row">
        <TopCard
          title="Total Calls"
          text={`${callHistoryDetailsTotalCounts || 0}`}
          icon="box"
          class="primary"
        />
      </div>
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card shadow mb-4" style={{ minHeight: "600px" }}>
            <div className="card-header py-3">
              <h6 className="m-0 font-weight-bold text-green">Call history</h6>
              <div className="header-buttons">
                <button
                  disabled={
                    callHistoryDetailsTotalCounts > 0 ? exportSpin : true
                  }
                  className="custom-button-icon"
                  onClick={ExportCSV}
                  title="Export Excel"
                >
                  {exportSpin ? (
                    <span className="loading">...</span>
                  ) : (
                    <i className="fas fa-file-export"></i>
                  )}
                </button>
                <button
                  className="custom-button-icon"
                  onClick={() => {
                    toggle("filterDrawer");
                  }}
                  title="Filter Calls"
                >
                  <i className="fas fa-filter"></i>
                </button>
                &nbsp;
              </div>
              <hr className="mt-4" />{" "}
              <div className="d-flex">
                <SearchBar
                  onChange={(e) => {
                    e.persist();
                    setState((prev) => ({
                      ...prev,
                      searchText: e.target.value,
                    }));
                  }}
                  onClear={() => {
                    setState((prev) => ({
                      ...prev,
                      searchText: "",
                    }));
                  }}
                  placeholder={"Search"}
                  value={searchText}
                />
                <div className="ml-2" style={{border:"1px solid #B2BEB5", borderRadius:"10px"}}>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  title={""}
                  maxDate={addDays(new Date(), 0)}
                  close={false}
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  className="date-picker "
                  showIcon
                  icon={
                    <svg
                      className="calender-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 48 48"
                    >
                      <mask id="ipSApplication0">
                        <g
                          fill="none"
                          stroke="#fff"
                          strokeLinejoin="round"
                          strokeWidth="4"
                        >
                          <path
                            strokeLinecap="round"
                            d="M40.04 22v20h-32V22"
                          ></path>
                          <path
                            fill="#fff"
                            d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
                          ></path>
                        </g>
                      </mask>
                      <path
                        fill="currentColor"
                        d="M0 0h48v48H0z"
                        mask="url(#ipSApplication0)"
                      ></path>
                    </svg>
                  }
                ></DatePicker>
                </div>
                
                <div className="ml-2 mr-2 custom-clear-select">
                  <Select
                    maxMenuHeight={180}
                    className="basic-single project-select"
                    classNamePrefix="select"
                    value={
                      selectedProjectId
                        ? projectList?.find(
                            (project) => project?._id === selectedProjectId
                          )
                        : null
                    }
                    isDisabled={false}
                    isSearchable={true}
                    options={projectList}
                    getOptionLabel={(e: any) => e?.projectName}
                    getOptionValue={(e: any) => e?._id}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        selectedProjectId: e?._id
                      }))
                    }
                    placeholder="Select Project"
                    styles={customStyle}
                    isClearable={true}
                  />
                </div>
                <button
                  className="custom-clear-btn"
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      selectedProjectId: "",
                      searchText:""
                    }));
                  }}
                >
                  Clear
                </button>
                <button
                  className="call-history-button ml-2"
                  title="Click here to apply date filter"
                  onClick={callFn}
                  style={{ zIndex: 100 }}
                >
                  Apply Filters
                </button>{" "}
              </div>{" "}
            </div>

            <div className="card-body">
              <Scrollbars
                style={{
                  height: "85vh",
                  color: "white",
                  borderRadius: "10px",
                  // border: "1px solid #d17842",
                }}
                renderThumbVertical={renderThumb}
                autoHide={true}
              >
                {" "}
                <table
                  className="table table-hover"
                  style={{ textAlign: "center" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Number</th>
                      <th scope="col" style={{ width: "200px" }}>
                        Calling Status
                      </th>
                      <th scope="col">
                        {trackEnabledRecording?.length > 0
                          ? "Outcomes"
                          : "UUID"}
                      </th>
                      <th scope="col">Cost</th>
                      <th scope="col">Summary</th>
                      <th className="w-20">Recording</th>
                      <th>Action </th>
                    </tr>
                  </thead>
                  {loading ? (
                    <tr className="text-center">
                      <td colSpan={10}>
                        <LoadingDotsSpin />
                      </td>
                    </tr>
                  ) : (
                    <tbody>
                      {}
                      {campaignRunListData?.length > 0 ? (
                        productElementsAlreadyRun
                      ) : (
                        <tr className="text-center">
                          <td colSpan={10}>No data found</td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </Scrollbars>
            </div>
            <div className="card-footer float-left d-flex">
              <Pagination
                count={Math.ceil(
                  Number(callHistoryDetailsTotalCounts) / PAGE_SIZE_50
                )}
                onChange={(e, v) => {
                  handlePageNextInScroll1(v);
                }}
                page={Number(pageIndex)}
                variant="outlined"
                shape="rounded"
              />
            </div>
          </div>
        </div>
      </div>

      {filterDrawer && (
        <CommonDrawer
          toggle={() => toggle("filterDrawer")}
          html={<FilterDrawerBody obj={obj} all={true} main={true} />}
          open={filterDrawer}
        />
      )}

      {summaryModal && (
        <SummaryModel
          state={state}
          setState={setState}
          show={summaryModal}
          toggle={() => toggle("summaryModal")}
        />
      )}
      {call_uuid && (
        <CallLogs
          call_uuid={call_uuid}
          show={show}
          toggle={() => {
            setCall_uuid("");
            setShow(false);
          }}
        />
      )}
    </div>
  );
}

export default CallHistory;
